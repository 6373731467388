@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,opsz,wght@0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700&display=swap');

@font-face {
  font-family: "NunitoExpanded";
  src: url("../assets/fonts/nunito/expanded/NunitoSans_10pt_Expanded-Regular.woff2")
      format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NunitoExpanded";
  src: url("../assets/fonts/nunito/expanded/NunitoSans_10pt_Expanded-Medium.woff2")
      format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NunitoExpanded";
  src: url("../assets/fonts/nunito/expanded/NunitoSans_10pt_Expanded-SemiBold.woff2")
      format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NunitoExpanded";
  src: url("../assets/fonts/nunito/expanded/NunitoSans_10pt_Expanded-Bold.woff2")
      format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NunitoExpanded";
  src: url("../assets/fonts/nunito/expanded/NunitoSans_10pt_Expanded-Black.woff2")
      format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansRounded";
  src: url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-100.woff2")
      format("woff2"),
    url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-100.woff")
      format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansRounded";
  src: url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-300.woff2")
      format("woff2"),
    url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-300.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansRounded";
  src: url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-500.woff2")
      format("woff2"),
    url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-500.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansRounded";
  src: url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-700.woff2")
      format("woff2"),
    url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-700.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansRounded";
  src: url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-900.woff2")
      format("woff2"),
    url("../assets/fonts/museo-sans-rounded/MuseoSansRounded-900.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #222;
}

h1 {
  font-size: 1.4em;
}

h2 {
  font-size: 1.3em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1.1em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.9em;
}
