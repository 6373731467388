.topbar {
  background: linear-gradient(
    90deg,
    rgba(198, 112, 116, 1) 0%,
    rgba(221, 156, 158, 1) 100%
  );
}

.topbar-arrow {
  background: url("../assets/images/icons/arrow-head.png") no-repeat center;
  background-size: 100%;
  width: 330px;
  height: 72px;
}

.topbar-search-icon {
  position: absolute;
  right: -10px;
  top: 0;
  background: url("../assets/images/icons/search-icon.png") no-repeat center;
  background-size: 100%;
  width: 64px;
  height: 64px;
}

.hero-slider,
.page-slider {
  position: relative;
}

.hero-slider:before {
  content: "";
  background: url("../assets/images/search-bg.png") no-repeat;
  background-position: center right;
  background-size: contain;
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.hero-slider:after {
  content: "";
  background: url("../assets/images/logo-bg.png") no-repeat;
  background-position: bottom left;
  background-size: 30%;
  position: absolute;
  z-index: 0;
  left: 48px;
  bottom: 48px;
  height: 100%;
  width: 100%;
}

.hero-slider .slick-arrow.slick-prev:before,
.hero-slider .slick-arrow.slick-next:before {
  display: none;
}

.hero-slider .slick-arrow.slick-prev {
  background: url("../assets/images/icons/carousel-arrow.png") no-repeat center;
  left: 0 !important;
}

.hero-slider .slick-arrow.slick-next {
  background: url("../assets/images/icons/carousel-arrow-next.png") no-repeat
    center;
  right: 0 !important;
}

.hero-slider .slick-arrow.slick-prev,
.hero-slider .slick-arrow.slick-next {
  background-size: contain;
  width: 60px;
  height: 60px;
  top: calc(50% + 80px);
}

.hero-slider .slick-dots {
  bottom: -80px;
}

.hero-slider .slick-dots li button,
.hero-slider .slick-dots li {
  width: 30px;
  height: 30px;
}

.hero-slider .slick-dots li button:before {
  font-size: 24px;
  color: #b5add0 !important;
  width: 30px;
}

.hero-slider .slick-dots li.slick-active button:before {
  color: #ff666e !important;
}

.hero-slider .violet-headline {
  color: #6857a0;
}

.hero-slider .red-headline {
  color: #ff666e;
}

.hero-section-slide-3 {
  background: url("../assets/images/icons/carousel-assets-arrow.png") no-repeat
    center;
  background-size: 85%;
}

.hero-section-1-image {
  background: url("../assets/images/icons/carousel-assets-1.png") no-repeat
    center;
}

.hero-section-2-image {
  background: url("../assets/images/icons/carousel-assets-2.png") no-repeat
    center;
}

.hero-section-3-image {
  background: url("../assets/images/icons/carousel-assets-3.png") no-repeat
    center;
}

.hero-section-1-image,
.hero-section-2-image,
.hero-section-3-image {
  background-size: contain;
  width: 270px;
  height: 270px;
}

.hero-slider-info {
  position: relative;
  overflow: hidden;
}

.hero-slider-info:after {
  content: "";
  background: url("../assets/images/logo-bg-light.png") no-repeat;
  background-position: bottom right;
  background-size: 250px;
  position: absolute;
  z-index: 0;
  right: 18px;
  bottom: 18px;
  height: 100%;
  width: 100%;
}

.sliderIcon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero-slider.page-slider:before,
.hero-slider.page-slider:after {
    display: none;
}

.hero-slider.page-slider .slick-arrow.slick-prev,
.hero-slider.page-slider .slick-arrow.slick-next {
    background-size: contain;
    width: 30px;
    height: 30px;
    top: 50%;
}

.grant-hero {
    width: 100%;
    /*background: rgb(255,255,255);*/
    /*background: linear-gradient(333deg, rgba(116,51,198,1) 0%, rgba(141,63,151,1) 71%, rgba(216,100,116,1) 100%);*/
    background-image: url("../assets/images/grant-gradient.jpg");
    position: relative;
    background-size: cover;
    background-position: center;
}

.grant-hero-image {
    background-image: url("../assets/images/grant.jpg");
    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.5;
}

.hero-gradient {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(117,51,200,1) 20%, rgba(117,51,200,1) 50%, rgba(200,101,125,1) 100%);
}

.img-gradient {
    background: linear-gradient(0deg, rgba(117, 51, 200, 1) 0%, rgb(48, 18, 158) 100%);
}

.footer-gradient {
    background: linear-gradient(270deg, rgba(117, 51, 200, 1) 0%, rgb(48, 18, 158) 100%);
}

.hr {
    background: linear-gradient(90deg, #30129E 0%, #7533C8 100%);
    height: 2px;
}

.border-gradient {
    background: linear-gradient(90deg, #ED6E70 0%, #7533C8 100%);
    height: 2px;
}

.hero-radial-gradient {
    background: linear-gradient(120deg, #30129E 25%, #30129E 70%, #ED6E70 100%);
}

.questions-gradient {
    background: linear-gradient(90deg, #30129E 0%, #7533E7 50%, #C8657D 100%);
}

.propose-gradient {
    background: linear-gradient(150deg, #30129E 0%, #7533E7 60%, #DB7475 95%, #DB7475 100%);
}

.wg-gradient {
    background: linear-gradient(120deg, #7533C8 0%, #30129E 100%);
}

.blue-gradient {
    background: linear-gradient(120deg, #30129E 0%, #7533C8 100%);
}

.contact-bg-gradient {
    background: linear-gradient(90deg, #C8657D 0%, #7533C8 100%);
}

.contact-reverse-bg-gradient {
    background: linear-gradient(90deg, #7533C8 0%, #C8657D 100%);
}

.oursystem-gradient {
    background: linear-gradient(0deg, #7533C8 0%, #30129E 100%);
}

@media (max-width: 992px) {
  .hero-section-1-image,
  .hero-section-2-image,
  .hero-section-3-image {
    background-size: contain;
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 767px) {
  .hero-section-1-image,
  .hero-section-2-image,
  .hero-section-3-image {
    background-size: contain;
    width: 150px;
    height: 150px;
  }

  .hero-slider .slick-arrow.slick-prev,
  .hero-slider .slick-arrow.slick-next {
    background-size: contain;
    width: 30px;
    height: 30px;
    top: calc(50% + 80px);
  }
}


