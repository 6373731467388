.auth-code input {
  border: 1px solid #ebeaed;
  border-radius: 5px !important;
  transition: all 0.15s ease-in-out;
  margin: 0 1px;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  width: 42px !important;
  height: 42px !important;
}
.auth-code input:focus {
  outline: none;
  border: 2px solid #845eee;
  box-shadow: 0 0 4px rgba(21, 123, 250, 0.4);
}
.auth-code.error input {
  border: 1px solid #ff4040;
}

.auth-code .auth-code-wrapper > div > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/*@media (max-width: 1200px) {
  .auth-code input {
    width: 42px !important;
    height: 42px !important;
  }
}*/
