.slick-dots li button:before {
  color: rgba(219, 234, 254, 1) !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  color: rgba(30, 64, 175, 1) !important;
  opacity: 1 !important;
}

.slick-dots li {
  margin: 0 !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

.slick-prev,
.slick-next {
    width: 30px;
    height: 30px;
}

.slick-prev:before,
.slick-next:before {
  color: #F2C8C7 !important;
    font-size: 30px !important;
}

.slick-prev, .slick-next {
    z-index: 10;
}
