@import "~lightgallery/css/lightgallery.css";
@import "~lightgallery/css/lg-zoom.css";
@import "~lightgallery/css/lg-video.css";

.wysiwyg-editor ul {
  list-style-type: disc;
  margin-left: 32px;
  margin-bottom: 12px;
}
.wysiwyg-editor ol {
  list-style-type: decimal;
  margin-left: 32px;
  margin-bottom: 12px;
}

.wysiwyg-editor a {
  color: rgba(124, 58, 237, var(--tw-text-opacity));
  text-decoration: underline;
    word-break: break-all;
}

.wysiwyg-editor h2,
.wysiwyg-editor h3 {
  margin-top: 20px;
}

.wysiwyg-editor p {
  padding: 8px 0;
}

.PhoneInputCountry {
  padding: 10px;
  border: 1px solid #ebeaed;
  border-radius: 5px;
}

.wg-summary,
.short-summary {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 122px;
}

.comment-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 164px;
}

.discussion-post {
  max-height: 138px;
  overflow: hidden;
}

.lightbox-container {
  background: rgba(0, 0, 0, 0.7);
}

.iframe-overscroll {
  overscroll-behavior-y: contain;
}
.iframe-overscroll {
  overscroll-behavior-y: none;
  height: 479px !important;
  overflow-y: scroll;
}

.iframe-overscroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.iframe-overscroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.iframe-helper {
    width: 100%;
    text-align: center;
    padding: 12px 0;
    position: fixed;
    opacity: 0;
    z-index: 100;
    top: -100px;
    left: 0;
    transition: all 0.2s;
}

.iframe-helper.open {
    top: 0;
    opacity: 0.95;
}

.top-arrow {
    background: url('../assets/images/icons/top-arrow.png');
    background-position: center;
    background-size: cover;
    width: 48px;
    height: 48px;
}
