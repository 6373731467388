.rmsc .dropdown-heading {
  height: auto !important;
  padding: 5px 10px !important;
  align-items: flex-start !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  white-space: normal !important;
}

.rmsc .dropdown-heading-value {
  font-size: 15px !important;
}

.rmsc .dropdown-heading-value .gray {
  display: block;
  margin: 3px 0 !important;
  transition: none !important;
}

.rmsc .dropdown-container[aria-disabled="true"] {
  background: #efefef !important;
}
