.quill {
  background: #fff;
}

.ql-editor {
  min-height: 120px;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
    font-style: normal !important;
    color: #77777F;
}
